import React from "react";

interface MetricBoxProps {
  title: string;
  value: number | string;
}

const MetricBox: React.FC<MetricBoxProps> = ({ title, value }) => (
  <div className="metric-box">
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

export default MetricBox;
