export const algoColours = {
  primary: "#006883", // Algorand Teal
  secondary1: "#DCFE54", // Arctic Lime
  secondary2: "#01DC94", // Med Aquamarine
  secondary3: "#31D8EE", // Sky Teal
  other1: "#201F21", // Algorand Coal
  other2: "#00A5CF", // Bright Teal
  other3: "#5BC0BE", // Muted Turquoise
  other4: "#005B84", // Deep Sapphire Blue
  other5: "#F9E076", // Soft Lemon
};

export const availableColors = Object.values(algoColours);

export const getColourForIndex = (index: number) => {
  // Use modulo to loop back to the start of the color array if there are more repos than colors
  return availableColors[index % availableColors.length];
};

export function getCumulativeData(data: number[]): number[] {
  let cumulativeSum = 0;
  return data.map((value) => {
    cumulativeSum += value;
    return cumulativeSum;
  });
}
