import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  registerables,
  ChartType,
  ChartData,
  ChartOptions,
} from "chart.js";

ChartJS.register(...registerables);

interface ChartComponentProps<T extends ChartType> {
  title: string;
  chartData: ChartData<T>;
  chartOptions?: ChartOptions<T>; // Use generic type for options
  chartType: T;
  cols?: number;
}

function ChartComponent<T extends ChartType>({
  title,
  chartData,
  chartOptions,
  chartType,
  cols = 6,
}: ChartComponentProps<T>) {
  let ChartComponent;
  switch (chartType) {
    case "bar":
      ChartComponent = Bar;
      break;
    case "line":
      ChartComponent = Line;
      break;
    case "pie":
      ChartComponent = Pie;
      break;
    default:
      throw new Error("Invalid chart type");
  }

  return (
    <div className={`chart-container ${chartType}`}>
      <div className="chart-header">
        <h2>{title}</h2>
      </div>
      <ChartComponent
        data={chartData as ChartData<any>}
        options={chartOptions as ChartOptions<any>}
      />
    </div>
  );
}

export default ChartComponent;
