export const SummaryComponent = () => {
  // TODO use the data in processed_metrics.json to dynamically get this from GPT4 (this is statically generated)
  return (
    <div className="p-4">
      <h2>Detailed Overview (Last 3 Months)</h2>
      <h3>December 2023</h3>
      <ol>
        <li>
          <strong>Build dist output using rollup or tsup</strong>: An
          enhancement request that is still open.
        </li>
        <li>
          <strong>Fix 3.12 compatibility</strong>: Open issue without comments
          or labels.
        </li>
        <li>
          <strong>Conduit container is not starting</strong>: A complex issue
          involving errors with Postgres and Conduit containers. The issue was
          resolved through multiple troubleshooting steps, including{" "}
          <code>algokit localnet reset</code>, updating docker images, and
          additional debugging. The issue was eventually closed.
        </li>
        <li>
          <strong>localnet status fails even when "good"</strong>: A bug in the{" "}
          <code>localnet status</code> command was reported and fixed in a
          subsequent release.
        </li>
      </ol>

      <h3>November 2023</h3>
      <ol>
        <li>
          <strong>Remove dependency on cross-fetch</strong>: The issue was
          closed after determining that the removal of cross-fetch was already
          addressed in earlier releases.
        </li>
        <li>
          <strong>
            Further investigate imports from algokit utils on cjs failing to
            resolve module when running eslint
          </strong>
          : Still open without comments or labels.
        </li>
        <li>
          <strong>kmdClient defaults not retrieved properly</strong>: Open issue
          without comments or labels.
        </li>
        <li>
          <strong>
            Problem with signing ABI transaction argument with dedicated signer
          </strong>
          : A closed bug issue.
        </li>
        <li>
          <strong>Algonode Retry Fails 429 JSON Failure (Indexer)</strong>: A
          bug involving retry logic in the algosdk was discussed and resolved.
        </li>
        <li>
          <strong>ESM Module Import Error</strong>: Issue with module imports
          was fixed and released in <code>5.0.0</code>.
        </li>
        <li>
          <strong>
            Update AlgoSDK: Failed to execute on Window: illegal invocation
          </strong>
          : Issue related to AlgoSDK's peer dependency was addressed.
        </li>
        <li>
          <strong>
            Fix typo in generators copier.yaml to be able to execute the copier
            task that informs about successful execution
          </strong>
          : The typo was fixed in subsequent releases.
        </li>
        <li>
          <strong>Transition to Ruff Formatter Post Stable Release</strong>:
          Open enhancement issue.
        </li>
        <li>
          <strong>
            Update the IPFS upload task to use the new web3.storage api
          </strong>
          : Closed enhancement issue.
        </li>
        <li>
          <strong>
            algokit goal - automatically run the localnet docker container if
            it's not already running
          </strong>
          : Open enhancement issue.
        </li>
        <li>
          <strong>Update Python VSCode settings</strong>: Closed issue regarding
          Python formatting/linting settings.
        </li>
        <li>
          <strong>Use official indexer image for localnet</strong>: Closed
          enhancement issue.
        </li>
        <li>
          <strong>Running generate with answers returns an error</strong>: A fix
          was released in <code>1.6.3</code>.
        </li>
        <li>
          <strong>Issues beaker with importation</strong>: Closed issue related
          to dependency installation and interpreter issues.
        </li>
        <li>
          <strong>
            Prompt user to update AlgoKit when new version is detected
          </strong>
          : Open issue with discussions on improving version update
          notifications.
        </li>
      </ol>

      <h3>October 2023</h3>
      <ol>
        <li>
          <strong>Latest release breaks some type imports</strong>: Closed bug
          issue with discussions on TypeScript versions and settings.
        </li>
        <li>
          <strong>
            docker compose 2.21.0 introduces breaking change to <code>ps</code>{" "}
            JSON output
          </strong>
          : Closed without comments.
        </li>
        <li>
          <strong>algokit localnet start - ledger does not have entry 1</strong>
          : Discussions on possible causes and resolutions for the issue, which
          was eventually closed.
        </li>
        <li>
          <strong>Template variables parsing</strong>: Closed issue with a fix
          for parsing template variables.
        </li>
      </ol>

      <h3>Earlier in 2023</h3>
      <p>
        Various issues ranging from enhancement requests, bug reports, and
        closed issues were discussed and addressed in different ways. Some
        issues were resolved through updates and releases, while others remained
        open for further investigation or enhancement.
      </p>
    </div>
  );
};
