import MetricBox from "./MetricBox";
import processedMetrics from "./processed_metrics.json";
import "./App.css";
import MetricData, {
  AgentMetrics,
  ManagedRepository,
  UnsolvedTicket,
} from "./MetricData";
import ChartComponent from "./ChartComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import TableComponent, { TableColumn } from "./TableComponent";
import { SummaryComponent } from "./SummaryComponent";
import {
  algoColours,
  getColourForIndex,
  getCumulativeData,
} from "./ChartUtils";

const App = () => {
  const data: MetricData = processedMetrics as MetricData;

  const ticketsByDateData = {
    labels: Object.keys(data.created_tickets_monthly),
    datasets: [
      {
        label: "Created Tickets",
        data: Object.values(data.created_tickets_monthly),
        borderColor: algoColours.primary,
        tension: 0.4,
      },
      {
        label: "Solved Tickets",
        data: Object.values(data.solved_tickets_monthly),
        borderColor: algoColours.secondary2,
        tension: 0.4,
      },
    ],
  };

  const ticketsByLabelData = {
    labels: Object.keys(data.tickets_by_label),
    datasets: [
      {
        label: "Number of Tickets",
        data: Object.values(data.tickets_by_label),
        backgroundColor: algoColours.primary,
        borderColor: algoColours.secondary3,
        borderWidth: 1,
      },
    ],
  };

  const labelMonthlyData = data.tickets_by_month_and_label;
  const months = Object.keys(labelMonthlyData);
  const allTicketLabels = new Set<string>();
  months.forEach((month) => {
    Object.keys(labelMonthlyData[month]).forEach((label) =>
      allTicketLabels.add(label)
    );
  });
  const labelDatasets = Array.from(allTicketLabels).map((label, index) => {
    const dataPoints = months.map(
      (month) => labelMonthlyData[month][label] || 0
    );
    return {
      label,
      data: dataPoints,
      backgroundColor: getColourForIndex(index),
      tension: 0.4,
      fill: true,
    };
  });
  const ticketsByLabelMonthlyData = {
    labels: months,
    datasets: labelDatasets,
  };

  const ticketsMonths = Object.keys(data.tickets_by_month_and_repo);
  const ticketRepoNames = Array.from(
    new Set(
      ticketsMonths.flatMap((month) =>
        Object.keys(data.tickets_by_month_and_repo[month])
      )
    )
  );
  const ticketsDatasets = ticketRepoNames.map((repoName, index) => {
    const dataPoints = ticketsMonths.map(
      (month) => data.tickets_by_month_and_repo[month][repoName] || 0
    );
    return {
      label: repoName,
      data: dataPoints,
      borderColor: getColourForIndex(index),
      backgroundColor: getColourForIndex(index),
    };
  });
  const ticketsByMonthRepoData = {
    labels: ticketsMonths,
    datasets: ticketsDatasets,
  };

  const ticketsByFirstReplyDataCombined = {
    labels: [
      "< 1 day",
      "1-7 days",
      "8-31 days",
      "1-3 months",
      "> 3 months",
      "No reply",
    ],
    datasets: [
      {
        label: "Support Tickets",
        data: [
          data.support_first_reply_brackets["< 1 day"],
          data.support_first_reply_brackets["1-7 days"],
          data.support_first_reply_brackets["8-31 days"],
          data.support_first_reply_brackets["1-3 months"],
          data.support_first_reply_brackets["> 3 months"],
          data.support_first_reply_brackets["No reply"],
        ],
        backgroundColor: algoColours.primary,
      },
      {
        label: "Enhancement Tickets",
        data: [
          data.enhancement_first_reply_brackets["< 1 day"],
          data.enhancement_first_reply_brackets["1-7 days"],
          data.enhancement_first_reply_brackets["8-31 days"],
          data.enhancement_first_reply_brackets["1-3 months"],
          data.enhancement_first_reply_brackets["> 3 months"],
          data.enhancement_first_reply_brackets["No reply"],
        ],
        backgroundColor: algoColours.secondary2,
      },
    ],
  };

  const ticketsByFullResolutionDataCombined = {
    labels: ["< 1 day", "1-7 days", "8-31 days", "1-3 months", "> 3 months"],
    datasets: [
      {
        label: "Support Tickets",
        data: [
          data.support_full_resolution_brackets["< 1 day"],
          data.support_full_resolution_brackets["1-7 days"],
          data.support_full_resolution_brackets["8-31 days"],
          data.support_full_resolution_brackets["1-3 months"],
          data.support_full_resolution_brackets["> 3 months"],
        ],
        backgroundColor: algoColours.primary,
      },
      {
        label: "Enhancement Tickets",
        data: [
          data.enhancement_full_resolution_brackets["< 1 day"],
          data.enhancement_full_resolution_brackets["1-7 days"],
          data.enhancement_full_resolution_brackets["8-31 days"],
          data.enhancement_full_resolution_brackets["1-3 months"],
          data.enhancement_full_resolution_brackets["> 3 months"],
        ],
        backgroundColor: algoColours.secondary2,
      },
    ],
  };

  const medianReplyAndResolutionByMonthData = {
    labels: Object.keys(data.median_first_reply_time_by_month),
    datasets: [
      {
        label: "Median First Reply Time (Days)",
        data: Object.values(data.median_first_reply_time_by_month),
        borderColor: algoColours.secondary3,
        backgroundColor: algoColours.primary,
        fill: false,
        tension: 0.4,
      },
      {
        label: "Median Full Resolution Time (Days)",
        data: Object.values(data.median_full_resolution_time_by_month),
        borderColor: algoColours.secondary2,
        backgroundColor: algoColours.secondary2,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const agentMetricsColumns: TableColumn[] = [
    {
      header: "Agent",
      render: (metric: AgentMetrics & { agentName: string }) => (
        <span>{metric.agentName}</span>
      ),
    },
    {
      header: "Total Solved Tickets",
      render: (metric: AgentMetrics) => <span>{metric.solved_tickets}</span>,
    },
    {
      header: "Median First Reply Time (Days)",
      render: (metric: AgentMetrics) => (
        <span>{metric.median_first_reply_time.toFixed(2)}</span>
      ),
    },
    {
      header: "Median Full Resolution Time (Days)",
      render: (metric: AgentMetrics) => (
        <span>{metric.median_full_resolution_time.toFixed(2)}</span>
      ),
    },
    {
      header: "% One-Touch Tickets",
      render: (metric: AgentMetrics) => (
        <span>{metric.percent_one_touch.toFixed(2)}%</span>
      ),
    },
  ];
  const agentMetricsData = Object.entries(data.agent_metrics).map(
    ([agentName, metrics]) => ({
      agentName,
      ...metrics,
    })
  );

  const totalCommentsData = {
    labels: Object.keys(data.total_comments_by_month),
    datasets: [
      {
        label: "Total Comments",
        data: Object.values(data.total_comments_by_month),
        backgroundColor: algoColours.primary,
      },
    ],
  };

  const averageCommentsData = {
    labels: Object.keys(data.average_comments_by_month),
    datasets: [
      {
        label: "Average Comments per Ticket",
        data: Object.values(data.average_comments_by_month),
        borderColor: algoColours.secondary3,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const unsolvedTicketsByStatusData = {
    labels: ["New", "Pending"],
    datasets: [
      {
        data: [
          data.unsolved_metrics.new_tickets_count,
          data.unsolved_metrics.pending_tickets_count,
        ],
        backgroundColor: [algoColours.primary, algoColours.secondary2],
      },
    ],
  };

  const unsolvedTicketsByLabelData = {
    labels: Object.keys(data.unsolved_metrics.unsolved_tickets_by_label),
    datasets: [
      {
        data: Object.values(data.unsolved_metrics.unsolved_tickets_by_label),
        backgroundColor: Object.keys(
          data.unsolved_metrics.unsolved_tickets_by_label
        ).map((_, index) => getColourForIndex(index)),
      },
    ],
  };

  const unsolvedTicketsByMonthData = {
    labels: Object.keys(data.unsolved_metrics.unsolved_tickets_by_month),
    datasets: [
      {
        label: "New",
        data: Object.values(
          data.unsolved_metrics.unsolved_tickets_by_month
        ).map((item) => item.New),
        backgroundColor: algoColours.primary,
      },
      {
        label: "Pending",
        data: Object.values(
          data.unsolved_metrics.unsolved_tickets_by_month
        ).map((item) => item.Pending),
        backgroundColor: algoColours.secondary2,
      },
    ],
  };

  const unsolvedTicketsDataColumns: TableColumn[] = [
    {
      header: "ID",
      render: (rowData: UnsolvedTicket) => (
        <a href={rowData.URL} target="_blank" rel="noopener noreferrer">
          {rowData.ID}
        </a>
      ),
    },
    {
      header: "Assignee",
      render: (rowData: UnsolvedTicket) => rowData.Assignee,
    },
    { header: "Title", render: (rowData: UnsolvedTicket) => rowData.Title },
    {
      header: "Created Date",
      render: (rowData: UnsolvedTicket) =>
        new Date(rowData["Created date"]).toISOString().slice(0, 10),
    },
    {
      header: "Updated Date",
      render: (rowData: UnsolvedTicket) =>
        new Date(rowData["Updated date"]).toISOString().slice(0, 10),
    },
    { header: "Status", render: (rowData: UnsolvedTicket) => rowData.Status },
    { header: "Label", render: (rowData: UnsolvedTicket) => rowData.Label },
  ];

  const historicalBacklogByStatusByMonthData = {
    labels: Object.keys(data.historical_backlog_by_status_by_month),
    datasets: [
      {
        label: "New",
        data: Object.values(data.historical_backlog_by_status_by_month).map(
          (status) => status.New
        ),
        backgroundColor: algoColours.primary,
      },
      {
        label: "Pending",
        data: Object.values(data.historical_backlog_by_status_by_month).map(
          (status) => status.Pending
        ),
        backgroundColor: algoColours.secondary2,
      },
    ],
  };

  const historicalBacklogByStatusByWeekData = {
    labels: Object.keys(data.historical_backlog_by_status_by_week),
    datasets: [
      {
        label: "New Tickets",
        data: Object.values(data.historical_backlog_by_status_by_week).map(
          (status) => status.New
        ),
        backgroundColor: algoColours.primary,
      },
      {
        label: "Pending Tickets",
        data: Object.values(data.historical_backlog_by_status_by_week).map(
          (status) => status.Pending
        ),
        backgroundColor: algoColours.secondary2,
      },
    ],
  };

  const backlogByLabelWeekData = data.historical_backlog_by_label_and_week;
  const weeks = Object.keys(backlogByLabelWeekData);
  const allLabels = new Set<string>();
  weeks.forEach((week) => {
    Object.keys(backlogByLabelWeekData[week]).forEach((label) =>
      allLabels.add(label)
    );
  });
  const backlogByLabelDatasets = Array.from(allLabels).map((label, index) => {
    const dataPoints = weeks.map(
      (week) => backlogByLabelWeekData[week][label] || 0
    );
    return {
      label,
      data: dataPoints,
      borderColor: getColourForIndex(index),
      backgroundColor: getColourForIndex(index),
    };
  });
  const backlogByLabelChartData = {
    labels: weeks,
    datasets: backlogByLabelDatasets,
  };

  const historicalNpmPipData = {
    labels: Object.keys(data.npm_downloads_by_month),
    datasets: [
      {
        label: "NPM Downloads",
        data: getCumulativeData(Object.values(data.npm_downloads_by_month)),
        borderColor: getColourForIndex(0),
        fill: false,
        tension: 0.4,
      },
      {
        label: "PIP Downloads",
        data: getCumulativeData(Object.values(data.pip_downloads_by_month)),
        borderColor: getColourForIndex(1),
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const historicalBrewWhlData = {
    labels: Object.keys(data.brew_whl_downloads_by_month),
    datasets: [
      {
        label: "Brew WHL Downloads",
        data: getCumulativeData(
          Object.values(data.brew_whl_downloads_by_month)
        ),
        borderColor: getColourForIndex(0),
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const historicalStarsData = {
    labels: Object.keys(data.stars_per_month),
    datasets: [
      {
        label: "Github Stars",
        data: getCumulativeData(Object.values(data.stars_per_month)),
        borderColor: getColourForIndex(0),
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const managedReposColumns: TableColumn[] = [
    {
      header: "Name",
      render: (data: ManagedRepository) => (
        <a href={data.url} target="_blank" rel="noopener noreferrer">
          {data.name}
        </a>
      ),
    },
    {
      header: "Description",
      render: (data: ManagedRepository) => <span>{data.description}</span>,
    },
    {
      header: "Total Stars",
      render: (data: ManagedRepository) => <span>{data.total_stars}</span>,
    },
    {
      header: "Latest Release",
      render: (data: ManagedRepository) => <span>{data.latest_release}</span>,
    },
  ];
  const managedReposData = data.managed_repositories;

  const typeReleasesData = data.releases_by_month_and_type;
  const releaseMonths = Object.keys(typeReleasesData);
  const releaseTypes = Array.from(
    new Set(
      releaseMonths.flatMap((month) => Object.keys(typeReleasesData[month]))
    )
  );
  const releaseDatasets = releaseTypes.map((releaseType, index) => {
    const dataPoints = releaseMonths.map(
      (month) => typeReleasesData[month][releaseType] || 0
    );
    return {
      label: releaseType,
      data: dataPoints,
      borderColor: getColourForIndex(index),
      backgroundColor: getColourForIndex(index),
    };
  });
  const releaseChartData = {
    labels: releaseMonths,
    datasets: releaseDatasets,
  };

  const repoReleasesData = data.releases_by_month_and_repo;
  const releaseRepoNames = Array.from(
    new Set(
      releaseMonths.flatMap((month) => Object.keys(repoReleasesData[month]))
    )
  );
  const repoReleaseDatasets = releaseRepoNames.map((repoName, index) => {
    const dataPoints = releaseMonths.map(
      (month) => repoReleasesData[month][repoName] || 0
    );
    return {
      label: repoName,
      data: dataPoints,
      borderColor: getColourForIndex(index),
      backgroundColor: getColourForIndex(index),
    };
  });

  const repoReleaseChartData = {
    labels: releaseMonths,
    datasets: repoReleaseDatasets,
  };
  const mergedPRsData = data.merged_pull_requests_by_month;
  const mergedPRsMonths = Object.keys(mergedPRsData);
  const repoNames = Array.from(
    new Set(
      mergedPRsMonths.flatMap((month) => Object.keys(mergedPRsData[month]))
    )
  );
  const mergedPRsDatasets = repoNames.map((repoName, index) => {
    const dataPoints = mergedPRsMonths.map(
      (month) => mergedPRsData[month][repoName] || 0
    );
    return {
      label: repoName,
      data: dataPoints,
      borderColor: getColourForIndex(index),
      backgroundColor: getColourForIndex(index),
    };
  });
  const mergedPRsChartData = {
    labels: mergedPRsMonths,
    datasets: mergedPRsDatasets,
  };

  const tweetsByDayData = {
    labels: Object.keys(data.tweets_by_day),
    datasets: [
      {
        label: "AlgoKit Twitter Mentions",
        data: Object.values(data.tweets_by_day),
        fill: false,
        borderColor: algoColours.secondary3,
        tension: 0.4,
      },
    ],
  };

  // TODO use the data in processed_metrics.json to dynamically get the exec summary from GPT4 (this is statically generated)
  return (
    <div className="container-xl my-4" id="report">
      <h1 className="text-center mb-4">AlgoKit Managed Services</h1>
      <div>
        <h2>Executive Summary: Last 12 Months Overview</h2>
        <p>
          Throughout 2023, significant progress was made in addressing various
          issues and enhancement requests. Key achievements include the
          resolution of complex technical challenges, significant bug fixes, and
          the implementation of important enhancements. The team demonstrated
          adaptability and efficiency, resolving tickets across multiple areas
          such as compatibility, debugging, and module imports. Notable
          accomplishments include fixing compatibility tickets, enhancing docker
          container functionalities, and improving software reliability through
          comprehensive debugging. The year also saw proactive steps in updating
          and refining our development tools and processes, contributing to
          overall project health and sustainability. MakerX is not contractually
          responsible for ticket triage but began assisting with this on a
          best-effort basis to help reduce first response times. While some
          tickets remain open for further investigation or enhancement, the past
          12 months have been marked by successful product delivery to market
          and fantastic levels of end-user satisfaction.
        </p>
      </div>
      <div className="metrics-row">
        <div className="chart-header">
          <h2>Tickets</h2>
        </div>
        <MetricBox title="Created Tickets" value={data.total_created_tickets} />
        <MetricBox
          title="Unsolved Tickets"
          value={data.total_unsolved_tickets}
        />
        <MetricBox title="Solved Tickets" value={data.total_solved_tickets} />
        <MetricBox title="Reopened Tickets" value={data.reopened_count} />
      </div>
      <div className="row">
        <ChartComponent
          title="Created and Solved Tickets by Month"
          chartData={ticketsByDateData}
          chartType="line"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Created Tickets by Label"
          chartData={ticketsByLabelData}
          chartType="bar"
          chartOptions={{ indexAxis: "y" }}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Created Tickets by Month and Repository"
          chartData={ticketsByMonthRepoData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="row">
        <div className="chart-header">
          <h2>Managed Repositories</h2>
        </div>
        <TableComponent columns={managedReposColumns} data={managedReposData} />
      </div>
      <div className="metrics-row section">
        <div className="chart-header">
          <h2>Pull Requests</h2>
        </div>
        <MetricBox
          title="Created PRs"
          value={data.pull_request_metrics.total_created_prs}
        />
        <MetricBox
          title="Merged PRs"
          value={data.pull_request_metrics.total_merged_prs}
        />
        <MetricBox
          title="Open PRs"
          value={data.pull_request_metrics.total_open_prs}
        />
        <MetricBox
          title="Abandoned PRs"
          value={data.pull_request_metrics.total_abandoned_prs}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Merged Pull Requests by Month"
          chartData={mergedPRsChartData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Cumulative NPM and PIP Downloads (Download Month)"
          chartData={historicalNpmPipData}
          chartType="line"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Cumulative algokit-cli Brew Downloads (Release Month)"
          chartData={historicalBrewWhlData}
          chartType="line"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Cumulative Github Stars"
          chartData={historicalStarsData}
          chartType="line"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="AlgoKit Twitter Mentions / Retweets by Day"
          chartData={tweetsByDayData}
          chartType="line"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Releases by Month and Type"
          chartData={releaseChartData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Releases by Month and Repository"
          chartData={repoReleaseChartData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="metrics-row section">
        <div className="chart-header">
          <h2>Response Times</h2>
        </div>
        <MetricBox
          title="Support Median First Reply Time (days)"
          value={(data.median_first_reply_time_non_enhancement / 24).toFixed(1)}
        />
        <MetricBox
          title="Support Median Full Resolution Time (days)"
          value={(
            data.median_full_resolution_time_non_enhancement / 24
          ).toFixed(1)}
        />
        <MetricBox
          title="Enhancement Median First Reply Time (days)"
          value={(data.median_first_reply_time_enhancement / 24).toFixed(1)}
        />
        <MetricBox
          title="Enhancement Median Full Resolution Time (days)"
          value={(data.median_full_resolution_time_enhancement / 24).toFixed(1)}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Tickets by First Reply Time Brackets"
          chartData={ticketsByFirstReplyDataCombined}
          chartType="bar"
          chartOptions={{ indexAxis: "y" }} // Set for horizontal bar chart
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Tickets by Full Resolution Time Brackets"
          chartData={ticketsByFullResolutionDataCombined}
          chartType="bar"
          chartOptions={{ indexAxis: "y" }} // Set for horizontal bar chart
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Median First Reply and Full Resolution Time by Created Month"
          chartData={medianReplyAndResolutionByMonthData}
          chartType="line"
        />
      </div>
      <div className="row">
        <div className="chart-header">
          <h2>Agent Metrics</h2>
        </div>
        <TableComponent columns={agentMetricsColumns} data={agentMetricsData} />
      </div>
      <div className="metrics-row section">
        <div className="chart-header">
          <h2>Comments</h2>
        </div>
        <MetricBox
          title="Total Comments"
          value={data.public_comments + data.internal_comments}
        />
        <MetricBox
          title="General Public Comments"
          value={data.public_comments}
        />
        <MetricBox title="Maintainer Comments" value={data.internal_comments} />
      </div>
      <div className="row">
        <ChartComponent
          title="Total Comments by Month"
          chartData={totalCommentsData}
          chartType="bar"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Average Comments per Ticket by Month"
          chartData={averageCommentsData}
          chartType="line"
        />
      </div>
      <div className="metrics-row section">
        <div className="chart-header">
          <h2>In Progress</h2>
        </div>
        <MetricBox
          title="Unsolved Tickets"
          value={data.unsolved_metrics.unsolved_tickets_count}
        />
        <MetricBox
          title="New Tickets"
          value={data.unsolved_metrics.new_tickets_count}
        />
        <MetricBox
          title="Pending Tickets"
          value={data.unsolved_metrics.pending_tickets_count}
        />
        <MetricBox
          title="Time Since Update Median (Days)"
          value={data.unsolved_metrics.time_since_update_median.toFixed(1)}
        />
        <MetricBox
          title="Ticket Age Median (Days)"
          value={data.unsolved_metrics.ticket_age_median.toFixed(1)}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Unsolved Tickets by Status"
          chartData={unsolvedTicketsByStatusData}
          chartType="pie"
        />
        <ChartComponent
          title="Unsolved Tickets by Label"
          chartData={unsolvedTicketsByLabelData}
          chartType="pie"
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Unsolved Tickets by Creation Month"
          chartData={unsolvedTicketsByMonthData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="row">
        <div className="chart-header">
          <h2>Unsolved Tickets</h2>
        </div>
        <TableComponent
          data={data.unsolved_metrics.unsolved_tickets_table}
          columns={unsolvedTicketsDataColumns}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Open Backlog by Label (12 Weeks)"
          chartData={backlogByLabelChartData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Open Backlog by Status (12 Months)"
          chartData={historicalBacklogByStatusByMonthData}
          chartType="bar"
          chartOptions={{
            scales: { x: { stacked: true }, y: { stacked: true } },
          }}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Open Backlog by Status (12 Weeks)"
          chartData={historicalBacklogByStatusByWeekData}
          chartType="bar"
          chartOptions={{
            scales: { x: { stacked: true }, y: { stacked: true } },
          }}
        />
      </div>
      <div className="row">
        <ChartComponent
          title="Created Tickets by Label Monthly"
          chartData={ticketsByLabelMonthlyData}
          chartType="bar"
          chartOptions={{
            scales: {
              x: { stacked: true },
              y: { stacked: true },
            },
          }}
        />
      </div>
      <div className="row">
        <SummaryComponent />
      </div>
    </div>
  );
};

export default App;
